<script>
import Swal from "sweetalert2";
import { minLength, required, sameAs, maxLength } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import Auth from "@/services/Auth"; // Update the import path based on your structure
import validationMessages from '@/components/validations'


export default {
  components:{
    validationMessages
  },
  page: {
    title: "Recoverpwd",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
    version() {
      return process.env.VUE_APP_VERSION;
    },
  },
  props: {
    userData: {
      type: Object,
      default: null,
    },
  },
  watch: {
    userData(newVal) {
      this.id_e = newVal.id;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
    const urlParams = new URLSearchParams(window.location.search);
    this.email = urlParams.get('email');
  },
  data() {
    return {
      id_e: "",
      password: "",
      confirm_password: "",
      errors: {},
      successMessage: "",
      user: null,
      submitted: false,
      showModal: false,
      tryingToEdit: false,
      title: "Recoverpwd",
      background: process.env.VUE_APP_LOGIN_IMAGE_URL,
      csrf_token: localStorage.getItem("csrf_token"),
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  validations: {
    password: {
      required,
      valid(value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSymbols = /[!#$%&'()*+,-.:;<=>?@^_`{|}~]/.test(value);
        return containsUppercase && containsLowercase && containsNumber && containsSymbols;
      },
      minLength: minLength(8),
      maxLength: maxLength(20),
    },
    confirm_password: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    async changeCRMPassword() {
      this.tryingToEdit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToEdit = false;
        return;
      }

      try {
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');
        const token = urlParams.get('token');
        const exp = urlParams.get('exp');

        if (!email) {
          this.tryingToEdit = false;
          return;
        }

        const response = await Auth.changeForgotPassword({
          password: this.password,
          confirm_password: this.confirm_password,
          email: email,
          token,
          exp
        });

        this.successmsg(response, () => {
          this.$router.push('/login'); // Redirect to login page
        });
        this.closeModal();
      } catch (error) {
        this.error = error.response?.data?.error || "";
        this.failedmsg(this.error);
      } finally {
        this.refreshData();
        this.tryingToEdit = false;
      }
    },

    successmsg(response, callback) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your password is changed",
        showConfirmButton: false,
        timer: 5000,
      }).then(() => {
        if (callback) callback();
      });
    },

    refreshData() {
      this.$emit("onRefresh");
    },

    closeModal() {
      this.showModal = false;
      this.resetProps();
    },

    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 5000,
      });
    },

    resetProps() {
      this.password = "";
      this.confirm_password = "";
      this.submitted = false;
    },

    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },

    toggleShowConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/login" class="text-dark"><i class="mdi mdi-home-variant h2"></i></router-link>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div>
              <div class="text-center">
                <router-link to="/" class="mb-1 d-block auth-logo">
                  <img
                      :src="background"
                      alt
                      height="150"
                      class="logo logo-dark"
                  />
                  <img
                      :src="background"
                      alt
                      height="150"
                      class="logo logo-light"
                  />
                </router-link>
              </div>
              <div class="card">
                <div class="card-body p-4">
                  <div class="text-center mt-2">
                    <h5 class="text-primary">Reset Password</h5>
                  </div>
                  <div class="p-2 mt-4">
                    <form @submit.prevent="changeCRMPassword">
                      <b-input-group class="mb-3">
                        <b-form-input
                            id="newPassword"
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            placeholder="Enter New Password"
                            :class="{'is-invalid': submitted && $v.password.$error}"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button variant="btn btn-outline-secondary" @click="toggleShowPassword">
                            <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                          </b-button>
                        </b-input-group-append>
                        <validationMessages v-if="submitted" :fieldName="'New Password'" :validationName="$v.password"></validationMessages>
                      </b-input-group>

                        <b-input-group class="mb-3">
                            <b-form-input
                                id="confirmNewPassword"
                                v-model="confirm_password"
                                :type="showConfirmPassword ? 'text' : 'password'"
                                placeholder="Confirm New Password"
                                :class="{'is-invalid': submitted && $v.confirm_password.$error}"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button variant="btn btn-outline-secondary" @click="toggleShowConfirmPassword">
                                <i :class="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                              </b-button>
                            </b-input-group-append>
                        </b-input-group>
                      <validationMessages v-if="submitted" :fieldName="'Confirm Password'" :validationName="$v.confirm_password" style="text-align: left;"></validationMessages>

                      <div class="mt-3 text-end">
                        <button
                            class="btn btn-primary w-sm waves-effect waves-light"
                            type="submit"
                            :disabled="tryingToEdit"
                        ><b-spinner v-show="tryingToEdit" small></b-spinner>
                          Reset
                        </button>
                      </div>
                      <input type="hidden" name="csrf_token" v-model="csrf_token">
                    </form>
                  </div>
                </div>
              </div>
              <div class="mt-5 text-center">
                <p>
                  Powered by
                  <span>
                    <a href="https://www.azerion.com/" target="_blank">Azerion</a>
                  </span>
                  <span class="d-block small">Version: {{ version }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
  </div>
</template>


<style>
/* Add any custom styles here */
</style>
